


















import { Component, Vue } from 'vue-property-decorator'
import { UserModule } from '@/store/modules'

@Component
export default class Logs extends Vue {
  @UserModule.State currentUser

  get userLogsTabs() {
    return [
      {
        name: 'LOGS_COUPON',
        component: () => import('@/components/Logs/UserCouponLogs.vue'),
        type: 'Coupon',
        icon: 'TagIcon', isActive: true
      },
      /* {
        name: 'LOGS_CONNECTION',
        component: () => import('@/components/Logs/UserConnectionLogs.vue'),
        type: 'Connection',
        icon: 'LogInIcon',
      }, */
      {
        name: 'LOGS_DONATION',
        component: () => import('@/components/Logs/UserDonationLogs.vue'),
        type: 'Donation',
        icon: 'DollarSignIcon'
      },
      {
        name: 'LOGS_SHOP',
        component: () =>import('@/components/Logs/UserShopLogs.vue'),
        type: 'Shop',
        icon: 'ShoppingBagIcon'
      },
      {
        name: 'LOGS_FORTUNE_WHEEL',
        component: () =>import('@/components/Logs/UserFortuneWheelLogs.vue'),
        type: 'Shop',
        icon: 'PieChartIcon'
      }
    ]
  }
}
